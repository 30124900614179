import {TFunction} from '@wix/yoshi-flow-editor'
import {MonthlyCalendarCellSizes} from '..'
import {isEditor} from '../../../../../../../../commons/selectors/environment'
import type {closeMonthlyCalendarPopup} from '../../../../../actions/calendar-layout'
import {
  getGridCellLocation,
  getEventDetailsStyleHash,
  getSelectedDate,
  getSelectedEventId,
} from '../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MonthlyCalendarPopup as MonthlyCalendarPopupPresentation} from './monthly-calendar-popup'

export interface MonthlyCalendarPopupOwnProps {
  getCellSizes: () => MonthlyCalendarCellSizes
  t: TFunction
}

export interface MonthlyCalendarPopupRuntimeProps {
  open: boolean
  week: number
  weekDayIndex: number
  selectedEventId: string
  popupStyleHash: string
  isEditor: boolean
  rtl: boolean
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface MonthlyCalendarPopupProps extends MonthlyCalendarPopupRuntimeProps, MonthlyCalendarPopupOwnProps {}

export interface MonthlyCalendarState {
  contentHeight: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarPopupRuntimeProps => {
  const selectedDate = getSelectedDate(context.state)

  const insideEditor = isEditor(context.state)
  const {weekIndex, weekDayIndex} = getGridCellLocation(context.state, selectedDate)

  return {
    week: weekIndex,
    weekDayIndex,
    open: Boolean(selectedDate),
    selectedEventId: getSelectedEventId(context.state),
    popupStyleHash: insideEditor && getEventDetailsStyleHash(context),
    isEditor: insideEditor,
    rtl: context.isRTL,
    closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
  }
}

export const MonthlyCalendarPopup = connect<MonthlyCalendarPopupOwnProps, MonthlyCalendarPopupRuntimeProps>(mapRuntime)(
  MonthlyCalendarPopupPresentation,
)
